export const SecondStep = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#00AD87" fillOpacity="0.5" />
      <path
        d="M7.834 15C7.582 15 7.4 14.944 7.288 14.832C7.176 14.7107 7.12 14.5473 7.12 14.342C7.12 14.2113 7.15267 14.0853 7.218 13.964C7.28333 13.8427 7.37667 13.7167 7.498 13.586L10.48 10.394C10.9373 9.90867 11.264 9.46533 11.46 9.064C11.6653 8.65333 11.768 8.24267 11.768 7.832C11.768 7.30933 11.6093 6.91267 11.292 6.642C10.9747 6.37133 10.508 6.236 9.892 6.236C9.51867 6.236 9.15933 6.292 8.814 6.404C8.46867 6.516 8.12333 6.698 7.778 6.95C7.638 7.04333 7.50733 7.08533 7.386 7.076C7.26467 7.06667 7.15733 7.02 7.064 6.936C6.98 6.852 6.924 6.74933 6.896 6.628C6.868 6.50667 6.87267 6.38067 6.91 6.25C6.94733 6.11933 7.036 6.00733 7.176 5.914C7.55867 5.62467 8.002 5.40067 8.506 5.242C9.01 5.08333 9.52333 5.004 10.046 5.004C10.7273 5.004 11.3013 5.11133 11.768 5.326C12.244 5.53133 12.6033 5.83467 12.846 6.236C13.0887 6.63733 13.21 7.12733 13.21 7.706C13.21 8.098 13.1447 8.48067 13.014 8.854C12.8927 9.22733 12.7013 9.61 12.44 10.002C12.188 10.3847 11.8567 10.7907 11.446 11.22L8.618 14.188V13.782H13.07C13.2753 13.782 13.434 13.8333 13.546 13.936C13.658 14.0387 13.714 14.188 13.714 14.384C13.714 14.58 13.658 14.734 13.546 14.846C13.434 14.9487 13.2753 15 13.07 15H7.834Z"
        fill="#00522F"
      />
    </svg>
  );
};
