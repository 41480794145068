import { createBrowserRouter } from 'react-router-dom';

import { MainView } from './views/MainView/index';
import { SuccessConection } from './views/SuccessConection/SuccessConection';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <MainView />,
  },
  {
    path: '/success',
    element: <SuccessConection />,
  },
]);
