export const FirstStep = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#00AD87" fillOpacity="0.5" />
      <path
        d="M8.17 15C7.974 15 7.82 14.944 7.708 14.832C7.596 14.72 7.54 14.5707 7.54 14.384C7.54 14.188 7.596 14.0387 7.708 13.936C7.82 13.8333 7.974 13.782 8.17 13.782H9.878V6.334H10.606L8.562 7.636C8.41267 7.72 8.27267 7.75267 8.142 7.734C8.02067 7.71533 7.91333 7.664 7.82 7.58C7.736 7.496 7.67533 7.39333 7.638 7.272C7.61 7.15067 7.61933 7.02467 7.666 6.894C7.722 6.76333 7.82 6.656 7.96 6.572L9.892 5.354C10.0507 5.26067 10.2 5.186 10.34 5.13C10.4893 5.06467 10.6293 5.032 10.76 5.032C10.9187 5.032 11.0493 5.07867 11.152 5.172C11.264 5.256 11.32 5.40067 11.32 5.606V13.782H12.888C13.0933 13.782 13.252 13.8333 13.364 13.936C13.476 14.0387 13.532 14.188 13.532 14.384C13.532 14.58 13.476 14.734 13.364 14.846C13.252 14.9487 13.0933 15 12.888 15H8.17Z"
        fill="#00522F"
      />
    </svg>
  );
};
