export const SuccessPicture = ({ className }: { className?: string }) => {
  return (
    <svg
      viewBox="0 0 267 238"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M153.71 207.148C205.959 207.148 248.315 205.328 248.315 203.084C248.315 200.839 205.959 199.019 153.71 199.019C101.46 199.019 59.104 200.839 59.104 203.084C59.104 205.328 101.46 207.148 153.71 207.148Z"
        fill="#E6E6E6"
      />
      <path
        d="M197.324 200.047V29.4807C197.324 24.4366 193.243 20.372 188.179 20.372H103.161C98.0968 20.372 94.0156 24.4366 94.0156 29.4807V200.047"
        fill="#EFEFEF"
      />
      <path
        d="M197.324 200.047V29.4807C197.324 24.4366 193.243 20.372 188.179 20.372H103.161C98.0968 20.372 94.0156 24.4366 94.0156 29.4807V200.047"
        stroke="#7D60F5"
        strokeWidth="2.28063"
        strokeMiterlimit="10"
      />
      <path
        d="M191.67 200.047V33.7901C191.67 29.1868 187.933 25.465 183.311 25.514H172.05V26.9342C172.05 30.7049 169.002 33.7901 165.166 33.7901H126.124C122.338 33.7901 119.24 30.7049 119.24 26.9342V25.514H107.98C103.358 25.514 99.6211 29.2358 99.6211 33.7901V200.047H191.67Z"
        fill="white"
      />
      <path
        d="M122.633 227.373H112.258V229.332H122.633V227.373Z"
        fill="#707070"
      />
      <path d="M191.621 25.465H99.3752V45.984H191.621V25.465Z" fill="white" />
      <path
        d="M46.9095 225.512C67.9558 225.512 85.0172 224.153 85.0172 222.476C85.0172 220.799 67.9558 219.44 46.9095 219.44C25.8632 219.44 8.80176 220.799 8.80176 222.476C8.80176 224.153 25.8632 225.512 46.9095 225.512Z"
        fill="#E6E6E6"
      />
      <path
        d="M29.9946 82.7123C29.9946 82.7123 27.4377 84.3284 26.0609 89.4214C24.7333 94.5144 23.1106 100.146 22.2747 102.301C21.488 104.456 19.5703 113.319 19.5703 118.167C19.5703 123.016 23.0615 123.554 27.1427 126.493C31.1747 129.431 37.3703 132.418 38.993 132.957C40.6156 133.495 43.8609 111.458 43.8609 110.675C43.8609 109.891 42.7792 93.2411 42.7792 93.2411L29.9946 82.7123Z"
        fill="white"
      />
      <path
        d="M27.8311 92.9963C27.8311 92.9963 26.4051 96.2284 26.0117 99.6564C25.6184 103.084 25.6184 104.309 24.3891 108.324C23.1598 112.34 22.1764 114.544 22.3731 116.6C22.5697 118.608 22.3731 119.833 24.7824 121.008C27.1918 122.183 27.3885 124.24 29.2079 125.219C31.0272 126.248 34.4692 127.031 34.4692 127.031C34.4692 127.031 35.0592 108.324 34.6659 107.492C34.2725 106.708 31.8139 99.0198 31.6173 98.4321C31.4697 97.8445 27.8311 92.9963 27.8311 92.9963Z"
        fill="#E1EAFB"
      />
      <path
        d="M37.4195 207.393C37.4195 207.393 36.4852 210.429 36.8294 211.947C37.2228 213.465 39.4847 214.396 37.9604 214.984C36.4361 215.571 32.0598 214.592 30.3388 213.27C29.0112 211.947 31.0764 211.164 31.4698 210.233C31.8631 209.303 32.2073 205.091 32.2073 205.091L37.4195 207.393Z"
        fill="#F9BDBD"
      />
      <path
        d="M36.7802 210.674C35.6984 210.038 34.2725 209.548 34.2233 211.115C34.125 212.584 34.6658 214.053 35.7476 215.131C36.6819 215.228 37.4686 215.228 38.0095 215.033C39.5338 214.445 37.2719 213.514 36.8786 211.996C36.731 211.556 36.731 211.164 36.7802 210.674Z"
        fill="#FFC7C6"
      />
      <path
        d="M47.303 219.783C47.303 219.783 47.6963 220.272 47.1063 221.105C46.5162 221.889 37.0753 222.035 34.8626 221.497C32.6499 221.007 28.372 219.489 27.782 218.265C27.6836 216.942 28.9129 215.62 28.9129 215.62L47.303 219.783Z"
        fill="#282828"
      />
      <path
        d="M28.5196 217.04C29.0604 218.265 32.4041 219.783 34.8627 220.468L47.2538 220.958C47.6472 220.468 47.8439 219.44 47.1555 219.342C46.0245 219.342 41.9925 217.628 40.8615 216.893C39.7306 216.159 39.7306 214.249 38.2063 213.661C32.3057 214.788 30.1914 211.751 30.1914 211.751C29.2571 213.27 29.4538 214.004 29.4538 214.004C29.4538 214.004 27.9295 215.718 28.5196 217.04Z"
        fill="#282828"
      />
      <path
        d="M34.8627 220.468H37.6654C37.2721 220.224 36.8787 219.93 36.5345 219.538C34.1251 216.844 30.8306 215.963 28.9621 216.257C28.7163 216.306 28.5196 216.355 28.3721 216.453C28.3721 216.649 28.4212 216.845 28.4704 216.991C29.0113 218.265 32.4041 219.783 34.8627 220.468Z"
        fill="#282828"
      />
      <path
        d="M35.1578 136.532C35.1578 136.532 35.4036 145.934 36.7804 151.027C38.108 156.12 38.9439 163.123 38.4031 169.293C37.8622 175.464 35.6986 180.312 35.4528 184.866C35.2069 189.421 31.2241 205.581 31.4699 207.197C31.7158 208.519 35.797 209.058 38.2064 208.274C39.534 207.197 47.3031 182.222 50.0075 176.541C52.7119 170.909 51.6301 166.061 51.876 162.829C52.1218 159.597 53.2036 136.238 53.2036 136.238C53.2036 136.238 41.0583 132.565 35.1578 136.532Z"
        fill="#373754"
      />
      <path
        d="M48.0898 161.115C47.3031 169.195 43.6152 168.363 44.4511 173.995C45.2379 179.626 45.2379 180.851 42.4351 186.874C39.9274 192.163 35.4036 202.888 35.7478 208.617C36.6329 208.617 37.4688 208.47 38.2064 208.226C39.534 207.148 47.3031 182.173 50.0075 176.492C52.7119 170.861 51.6301 166.012 51.876 162.78C52.1219 159.548 53.2036 136.189 53.2036 136.189C53.2036 136.189 53.0561 136.14 52.8103 136.091L46.8605 139.715C46.8605 139.813 48.9257 153.133 48.0898 161.115Z"
        fill="#46466D"
      />
      <path
        d="M64.8572 208.372C64.8572 208.372 64.4638 210.968 65.0539 212.486C65.6439 214.004 66.4798 214.396 66.1848 214.788C65.8898 215.179 61.5135 216.208 59.94 215.375L59.645 214.69C59.645 214.69 60.1367 212.78 60.2351 211.164C60.3334 209.548 60.1367 208.421 60.1367 208.421L64.8572 208.372Z"
        fill="#F9BDBD"
      />
      <path
        d="M64.7095 210.086C63.8736 209.939 62.841 210.086 62.5952 211.409C62.3493 212.829 62.841 214.494 63.6769 215.571C64.9062 215.375 65.988 215.032 66.1355 214.837C66.4305 214.445 65.6438 214.053 65.0045 212.535C64.7587 211.8 64.7095 210.919 64.7095 210.086Z"
        fill="#FFC7C6"
      />
      <path
        d="M58.9566 219.489C58.9566 219.489 58.5632 220.517 58.8583 221.203C59.1533 221.888 66.234 221.693 68.8892 221.595C71.4953 221.497 73.7572 221.595 74.2489 221.203C74.7406 220.811 74.2489 220.076 74.2489 220.076L58.9566 219.489Z"
        fill="#282828"
      />
      <path
        d="M59.4973 214.739C59.4973 214.739 62.9392 215.424 66.2337 214.543C67.3647 214.837 67.758 215.865 67.758 215.865C67.758 215.865 68.5939 217.285 69.8724 217.677C71.2 218.069 73.8553 218.803 74.2486 220.077C74.2486 220.762 67.2171 220.468 64.3652 220.468C61.5133 220.468 59.4973 220.566 58.9564 220.174C58.4647 219.783 59.0547 216.355 59.2514 215.865C59.4973 215.375 59.4973 214.739 59.4973 214.739Z"
        fill="#282828"
      />
      <path
        d="M64.1197 218.069C62.3987 217.187 60.0384 217.432 58.9075 217.628C58.76 218.705 58.6616 219.93 58.9567 220.174C59.4484 220.566 61.5136 220.468 64.3655 220.468C65.5948 220.468 67.66 220.517 69.5777 220.517C69.1843 220.272 68.5942 220.076 67.8567 220.028C66.0373 220.028 66.4799 219.293 64.1197 218.069Z"
        fill="#282828"
      />
      <path
        d="M48.8765 142.506C48.8765 142.506 51.9251 149.166 53.3019 157.834C54.7278 166.502 56.1538 170.714 56.7439 176.541C57.3339 182.369 57.5306 197.893 58.1698 201.516C58.7599 205.14 58.7599 207.54 58.9566 208.568C59.1533 209.597 62.9886 209.988 65.6439 208.568C66.6765 207.54 67.0698 192.261 67.2665 185.797C67.4632 179.333 67.0698 173.113 67.0698 168.069C67.0698 163.025 67.2665 149.754 67.4632 144.906C67.6599 140.058 66.8732 132.222 65.2505 130.41C63.6278 128.696 49.4665 135.748 48.8765 142.506Z"
        fill="#373754"
      />
      <path
        d="M64.0212 201.516C63.8737 204.7 63.7754 207.295 63.677 209.254C64.3654 209.107 65.0046 208.862 65.5947 208.568C66.6273 207.54 67.0207 192.261 67.2173 185.797C67.414 179.333 67.0207 173.113 67.0207 168.069C67.0207 163.025 67.2173 149.754 67.414 144.906C67.4632 143.633 67.4632 142.163 67.3649 140.694C66.0864 141.723 64.3654 143.192 63.5787 144.71C62.1527 147.305 63.382 154.161 63.5787 161.85C63.7754 169.489 61.956 167.09 63.382 173.309C64.808 179.528 64.6113 176.933 64.6113 185.013C64.6604 193.093 64.4638 193.926 64.0212 201.516Z"
        fill="#46466D"
      />
      <path
        d="M51.3843 66.6498C51.3843 66.6498 53.1053 69.4901 52.8594 70.7144C52.6136 71.9387 50.6467 71.5959 50.6467 71.5959L51.3843 66.6498Z"
        fill="#FFC7C6"
      />
      <path
        d="M50.3516 58.4226C50.3516 58.4226 51.8759 60.6263 51.8759 62.6831C51.8759 64.7399 52.7118 74.9259 51.335 76.1502C49.9582 77.3745 44.4019 76.0033 44.4019 76.0033C44.4019 76.0033 41.7958 75.3177 40.6649 71.449C39.5831 67.5802 42.8776 60.8712 42.8776 60.8712L50.3516 58.4226Z"
        fill="#FFC7C6"
      />
      <path
        d="M47.008 75.5136C47.008 75.5136 46.3688 78.8926 48.2373 81.8309C48.2373 81.8309 42.1401 81.3412 38.6489 81.6839C38.6489 81.6839 39.3865 75.8564 39.2882 74.0934C39.1406 72.3794 44.7953 73.6527 47.008 75.5136Z"
        fill="#FFC7C6"
      />
      <path
        d="M36.9279 56.9535C35.0594 58.3247 34.666 60.9691 34.666 60.9691C34.666 60.9691 32.06 61.8996 32.5025 66.0132C32.9942 70.1267 34.7644 71.351 35.6986 72.6243C36.6329 73.9465 36.4362 75.2198 37.5671 75.9053C38.6981 76.542 40.96 76.542 42.2384 76.2971C43.566 76.0033 44.5003 75.0728 44.4019 74.0444C44.3036 73.016 42.976 70.8613 43.6644 69.0984C44.3036 67.3354 45.1395 67.5313 45.6312 67.6292C46.1229 67.7272 46.7622 69.8819 46.7622 69.8819C46.7622 69.8819 47.6964 69.8819 47.6964 69.3432C47.6964 68.8045 47.1555 67.5802 47.3031 66.7477C47.4997 65.9152 47.6964 66.2091 48.0406 64.8868C48.0406 64.8868 50.8926 64.3971 50.45 61.3119C50.45 61.3119 52.0727 60.6263 52.3185 58.4716C52.5644 56.3169 50.5484 53.5255 47.3522 52.693C44.1069 51.9095 42.4843 55.6313 40.7633 55.7782C39.0423 55.8272 38.3047 55.9741 36.9279 56.9535Z"
        fill="#263238"
      />
      <path
        d="M37.3211 78.3049C40.2222 77.9621 45.7786 77.5704 48.8272 78.9905L51.0891 80.9983L34.8625 81.6839L35.1576 80.3617C35.3543 79.2843 36.2393 78.4518 37.3211 78.3049Z"
        fill="#E1EAFB"
      />
      <path
        d="M62.0052 95.2C62.0052 90.9395 60.8743 85.5037 57.7273 82.9572C54.5804 80.4107 47.1063 80.4107 47.1063 80.4107L34.5676 80.9984L29.9947 82.7124C29.9947 82.7124 26.8478 84.965 27.4378 89.5194C27.4378 89.5194 26.5036 93.8288 29.3063 102.301C32.1583 110.773 30.6831 119.686 31.8632 122.085C33.0925 124.485 33.8793 124.485 33.0925 128.109C32.3058 131.733 33.8792 138.588 35.9444 140.988C38.0096 143.388 49.319 146.228 56.5964 141.38C63.8737 136.532 65.4964 133.3 65.8897 130.9C66.2831 128.5 63.0378 113.564 63.0378 107.933C63.0378 102.301 62.0052 95.2 62.0052 95.2Z"
        fill="white"
      />
      <path
        d="M38.6488 142.653C42.9758 144.318 50.9908 145.151 56.5963 141.429C63.8736 136.581 65.4963 133.349 65.8897 130.949C66.283 128.549 63.0377 113.613 63.0377 107.981C63.0377 102.35 61.956 95.2 61.956 95.2C61.956 91.772 61.2184 87.6584 59.3499 84.8181C56.8913 84.7691 54.1377 85.1609 50.499 84.1815C43.2217 82.1737 34.7151 82.5654 31.8631 87.8054C29.0112 93.0453 33.4858 101.909 35.5018 109.989C37.5178 118.07 35.5018 118.853 35.1084 130.949C34.9609 136.483 36.6819 140.205 38.6488 142.653Z"
        fill="#E1EAFB"
      />
      <path
        d="M47.2538 67.5313C47.2538 67.5313 47.5489 70.7634 48.5815 71.7428C49.6141 72.7712 51.9251 71.449 52.4168 72.0366C52.9085 72.6243 52.6135 76.591 50.4991 76.8848C48.3848 77.1786 47.5489 76.493 47.7455 75.9543C47.9422 75.4646 48.3356 73.8486 47.7455 72.5263C47.1555 71.2041 46.3196 69.784 46.3196 69.1963C46.3196 68.6576 47.2538 67.5313 47.2538 67.5313Z"
        fill="#263238"
      />
      <path
        d="M46.3196 68.6086C46.3196 68.6086 46.1721 66.0621 44.9428 66.6988C43.7627 67.3354 44.0577 71.3021 45.4345 71.6449C46.7621 71.9387 46.4671 69.1473 46.3196 68.6086Z"
        fill="#FFC7C6"
      />
      <path
        d="M93.8682 81.9288C93.8682 81.9288 94.5566 81.4391 94.9991 80.3128C95.3925 79.1864 96.1301 75.6605 96.6218 74.6811C97.1135 73.6527 98.5395 69.5391 99.1295 69.0494C99.7196 68.5597 100.064 69.9798 99.6212 71.1551C99.2279 72.3794 98.4903 74.9749 98.4903 74.9749C98.4903 74.9749 99.8179 75.3667 100.703 76.1992C101.637 76.9827 102.227 78.207 102.326 78.9416C102.424 79.6272 102.129 80.7535 101.637 81.4391C101.146 82.1247 100.408 82.0267 100.408 82.0267C100.408 82.0267 99.4737 83.7407 98.392 83.7407C97.3102 83.7407 96.966 83.7407 96.966 83.7407L96.5726 84.965L93.1306 83.0551L93.8682 81.9288Z"
        fill="#FFC7C6"
      />
      <path
        d="M54.5311 81.4881C54.5311 81.4881 56.1537 80.9004 59.7924 83.6918C63.4311 86.4831 68.1023 91.5271 71.2985 93.9757C74.5438 96.3753 75.1338 96.5712 76.9532 97.0119C78.7725 97.4037 80.0018 95.0041 83.0012 91.772C86.0499 88.5399 89.8852 83.9366 91.7045 82.7123C93.5239 81.4881 93.5239 81.4881 93.5239 81.4881C93.5239 81.4881 97.1626 82.0757 97.7526 84.7202C98.3427 87.3156 91.7045 95.5918 89.4427 99.4115C87.23 103.231 81.9686 109.059 79.1167 109.891C76.2648 110.675 64.7587 104.26 62.3493 102.056C59.9399 99.8523 56.4979 98.0403 54.4819 95.5918C52.515 93.2411 48.2863 87.3646 54.5311 81.4881Z"
        fill="#E1EAFB"
      />
      <path
        d="M92.1472 84.5732C93.7698 83.9856 94.1632 84.5732 95.5892 84.965C96.3267 85.1609 96.9168 84.4753 97.2118 83.5449C95.9825 81.9288 93.524 81.537 93.524 81.537C93.524 81.537 93.524 81.537 91.7046 82.7613C89.8853 83.9856 86.0499 88.5889 83.0013 91.821C79.9527 95.0531 78.7726 97.4527 76.9533 97.0609C75.1339 96.6691 74.5439 96.4733 71.2986 94.0247C68.0533 91.6251 63.4312 86.5811 59.7925 83.7407C56.1538 80.9004 54.5312 81.537 54.5312 81.537C53.4986 82.4675 52.761 83.4469 52.2693 84.3774C53.3019 84.5243 55.4654 85.2588 59.2024 88.1971C64.8571 92.6045 66.0864 94.8572 70.5118 97.4527C74.9372 100.048 76.7566 103.084 79.0185 101.86C81.2312 100.636 80.6411 96.4243 83.8864 93.584C87.0826 90.7926 90.5245 85.1609 92.1472 84.5732Z"
        fill="white"
      />
      <path
        d="M49.6631 62.0955C49.9582 60.9691 47.3521 61.4099 47.9421 59.5C48.5322 57.5901 50.4499 56.5617 48.2372 54.6519C46.8112 53.4276 44.746 53.5745 43.4675 53.8193C42.435 54.6519 41.599 55.6313 40.7631 55.6802C38.993 55.8272 38.3046 55.9251 36.8786 56.9535C35.0101 58.3247 34.6167 60.9691 34.6167 60.9691C34.6167 60.9691 32.0106 61.8996 32.4532 66.0132C32.9449 70.1268 34.7151 71.351 35.6493 72.6243C35.9443 73.065 36.141 73.5058 36.2885 73.8975C40.1731 75.7584 42.8283 72.1346 42.435 70.1267C42.0416 68.021 43.3692 66.1111 44.746 64.0053C46.2211 61.8996 49.3681 63.2218 49.6631 62.0955Z"
        fill="#1C2528"
      />
      <path
        d="M198.16 56.3659H123.076C119.978 56.3659 117.421 58.8634 117.421 61.9975V82.8103C117.421 85.7976 119.781 88.2951 122.83 88.442C122.928 88.442 123.027 88.442 123.076 88.442H198.16V56.3659Z"
        fill="#C9C9C9"
      />
      <path
        d="M119.437 59.9408V80.8025C119.437 83.7897 121.797 86.2873 124.846 86.4342C124.944 86.4342 125.043 86.4342 125.092 86.4342H213.502C216.01 86.4342 218.222 84.7691 218.911 82.3696C219.058 81.8798 219.107 81.3412 219.107 80.8025V59.9408C219.107 56.8556 216.6 54.3091 213.453 54.3091H125.092C121.994 54.3091 119.437 56.8556 119.437 59.9408Z"
        fill="#A890FB"
      />
      <path
        d="M119.437 62.0465V80.8025C119.437 83.7897 121.797 86.2873 124.846 86.4342C124.944 86.4342 125.043 86.4342 125.092 86.4342H213.502C216.01 86.4342 218.222 84.7692 218.911 82.3696C219.058 81.8799 219.107 81.3412 219.107 80.8025V62.0465C219.107 61.9976 119.437 61.9976 119.437 62.0465Z"
        fill="#A890FB"
      />
      <path
        d="M203.078 65.1317C198.701 65.1317 195.112 68.6576 195.112 73.065C195.112 77.4235 198.652 80.9984 203.078 80.9984C207.454 80.9984 211.043 77.4725 211.043 73.065C211.043 68.7066 207.503 65.1317 203.078 65.1317Z"
        fill="white"
      />
      <path
        d="M210.355 78.5498L205.536 75.2198C205.389 74.7301 205.241 74.1914 205.192 73.6527C205.634 73.2609 205.979 72.7222 206.126 72.1346C206.126 72.1346 206.175 72.1346 206.175 72.1836C206.618 72.5264 207.405 68.9515 206.028 68.2659C206.028 68.2659 204.995 66.1111 203.078 66.6008C201.16 67.0906 200.029 67.8741 199.98 68.6087C199.931 69.3432 200.029 70.5185 200.029 70.5185C200.029 70.5185 200.127 69.686 200.767 69.3432C199.636 70.6165 199.783 72.5753 201.062 73.7017C201.209 73.8486 201.357 73.9465 201.553 74.0445C201.406 74.5342 201.209 75.1708 201.062 75.6605L199.586 76.3951L196.39 78.4029C196.39 78.4029 195.112 79.97 194.718 81.8309C194.669 81.9778 194.669 82.1247 194.62 82.2716H211.683C211.093 80.5087 210.355 78.5498 210.355 78.5498Z"
        fill="#E6E6E6"
      />
      <path
        d="M133.844 69.637H180.262C180.655 69.637 180.95 70.0288 180.95 70.5185C180.95 71.0082 180.655 71.4 180.262 71.4H133.844C133.451 71.4 133.156 71.0082 133.156 70.5185C133.156 69.9798 133.451 69.637 133.844 69.637Z"
        fill="white"
      />
      <path
        d="M133.844 64.0543H180.262C180.655 64.0543 180.95 64.4461 180.95 64.9358C180.95 65.4255 180.655 65.8173 180.262 65.8173H133.844C133.451 65.8173 133.156 65.4255 133.156 64.9358C133.156 64.4461 133.451 64.0543 133.844 64.0543Z"
        fill="white"
      />
      <path
        d="M141.663 74.779H180.066C180.459 74.779 180.754 75.1708 180.754 75.6605C180.754 76.1502 180.459 76.542 180.066 76.542H141.663C141.269 76.542 140.974 76.1502 140.974 75.6605C141.024 75.1218 141.319 74.779 141.663 74.779Z"
        fill="white"
      />
      <path
        d="M133.648 86.3852V92.5556C133.648 92.9473 133.943 93.2412 134.336 93.2412C134.533 93.2412 134.68 93.1922 134.779 93.0453L141.466 86.3852H133.648Z"
        fill="#A890FB"
      />
      <path
        d="M114.717 178.99C114.815 179.626 114.372 180.214 113.733 180.312C113.094 180.41 112.504 179.969 112.406 179.333C112.307 178.696 112.75 178.108 113.389 178.01C113.438 178.01 113.487 178.01 113.586 178.01C114.127 178.01 114.667 178.402 114.717 178.99Z"
        fill="#A890FB"
      />
      <path
        d="M118.306 178.99C118.405 179.626 117.962 180.214 117.323 180.312C116.684 180.41 116.093 179.969 115.995 179.333C115.897 178.696 116.339 178.108 116.979 178.01C117.028 178.01 117.077 178.01 117.175 178.01C117.765 178.01 118.257 178.402 118.306 178.99Z"
        fill="#A890FB"
      />
      <path
        d="M121.945 179.088C122.043 179.724 121.601 180.312 120.961 180.41C120.322 180.508 119.732 180.067 119.634 179.43C119.535 178.794 119.978 178.206 120.617 178.108C120.666 178.108 120.716 178.108 120.814 178.108C121.404 178.01 121.896 178.402 121.945 179.088Z"
        fill="#A890FB"
      />
      <path
        d="M174.116 105.582C174.214 106.219 173.771 106.806 173.132 106.904C172.493 107.002 171.903 106.561 171.804 105.925C171.706 105.288 172.149 104.7 172.788 104.602C172.837 104.602 172.886 104.602 172.985 104.602C173.575 104.505 174.066 104.994 174.116 105.582Z"
        fill="#A890FB"
      />
      <path
        d="M177.754 105.582C177.853 106.219 177.41 106.806 176.771 106.904C176.132 107.002 175.541 106.561 175.443 105.925C175.345 105.288 175.787 104.7 176.427 104.602C176.476 104.602 176.525 104.602 176.623 104.602C177.213 104.505 177.705 104.994 177.754 105.582Z"
        fill="#A890FB"
      />
      <path
        d="M181.393 105.631C181.491 106.268 181.049 106.855 180.409 106.953C179.77 107.051 179.18 106.61 179.082 105.974C178.983 105.337 179.426 104.749 180.065 104.651C180.114 104.651 180.164 104.651 180.262 104.651C180.803 104.505 181.344 104.994 181.393 105.631Z"
        fill="#A890FB"
      />
      <path
        d="M93.8188 159.548H175.787C178.885 159.548 181.442 157.051 181.442 153.916V133.055C181.442 130.068 179.082 127.57 176.033 127.423C175.935 127.423 175.837 127.423 175.787 127.423H93.8188V159.548Z"
        fill="#C9C9C9"
      />
      <path
        d="M179.426 131.096V151.958C179.426 154.945 177.066 157.442 174.017 157.589C173.919 157.589 173.821 157.589 173.771 157.589H85.3614C82.8537 157.589 80.6409 155.924 79.9525 153.525C79.805 153.035 79.7559 152.496 79.7559 151.958V131.096C79.7559 128.011 82.2636 125.464 85.4106 125.464H173.821C176.918 125.464 179.426 127.962 179.426 131.096Z"
        fill="#A890FB"
      />
      <path
        d="M179.426 133.202V151.958C179.426 154.945 177.066 157.442 174.017 157.589C173.919 157.589 173.821 157.589 173.771 157.589H85.3614C82.8537 157.589 80.6409 155.924 79.9525 153.525C79.805 153.035 79.7559 152.496 79.7559 151.958V133.202H179.426Z"
        fill="#A890FB"
      />
      <path
        d="M95.7858 136.287C100.162 136.287 103.752 139.813 103.752 144.22C103.752 148.579 100.211 152.154 95.7858 152.154C91.4096 152.154 87.8201 148.628 87.8201 144.22C87.8201 139.813 91.4096 136.287 95.7858 136.287Z"
        fill="white"
      />
      <path
        d="M88.5576 149.705L93.3764 146.375C93.5239 145.885 93.6714 145.347 93.7206 144.808C93.2781 144.416 92.9339 143.877 92.7863 143.29C92.7863 143.29 92.7372 143.29 92.7372 143.339C92.2946 143.681 91.5079 140.107 92.8847 139.421C92.8847 139.421 93.9173 137.266 95.835 137.756C97.7526 138.246 98.8836 139.029 98.9327 139.764C99.0311 140.498 98.8836 141.674 98.8836 141.674C98.8836 141.674 98.7852 140.841 98.146 140.498C99.2769 141.772 99.1294 143.73 97.851 144.857C97.7035 145.004 97.556 145.102 97.3593 145.2C97.5068 145.689 97.7035 146.326 97.851 146.816L99.3261 147.55L102.522 149.558C102.522 149.558 103.801 151.125 104.194 152.986C104.243 153.133 104.243 153.28 104.292 153.427H87.23C87.82 151.664 88.5576 149.705 88.5576 149.705Z"
        fill="#E6E6E6"
      />
      <path
        d="M116.143 142.506H162.56C162.954 142.506 163.249 142.114 163.249 141.625C163.249 141.135 162.954 140.743 162.56 140.743H116.143C115.749 140.743 115.454 141.135 115.454 141.625C115.454 142.163 115.799 142.506 116.143 142.506Z"
        fill="white"
      />
      <path
        d="M116.143 148.138H162.56C162.954 148.138 163.249 147.746 163.249 147.256C163.249 146.767 162.954 146.375 162.56 146.375H116.143C115.749 146.375 115.454 146.767 115.454 147.256C115.454 147.795 115.799 148.138 116.143 148.138Z"
        fill="white"
      />
      <path
        d="M116.143 137.364H130.452C130.845 137.364 131.14 136.972 131.14 136.483C131.14 135.993 130.845 135.601 130.452 135.601H116.143C115.749 135.601 115.454 135.993 115.454 136.483C115.454 137.021 115.799 137.364 116.143 137.364Z"
        fill="white"
      />
      <path
        d="M133.844 137.364H172.247C172.641 137.364 172.936 136.972 172.936 136.483C172.936 135.993 172.641 135.601 172.247 135.601H133.844C133.451 135.601 133.156 135.993 133.156 136.483C133.156 137.021 133.451 137.364 133.844 137.364Z"
        fill="white"
      />
      <path
        d="M165.216 157.589V163.76C165.216 164.151 164.921 164.445 164.527 164.445C164.331 164.445 164.183 164.396 164.085 164.249L157.397 157.589H165.216Z"
        fill="#A890FB"
      />
      <path
        d="M96.8675 152.349C103.792 152.349 109.406 147 109.406 140.4C109.406 133.801 103.792 128.451 96.8675 128.451C89.9426 128.451 84.3289 133.801 84.3289 140.4C84.3289 147 89.9426 152.349 96.8675 152.349Z"
        fill="#9378F8"
      />
      <path
        d="M94.3105 111.605C92.3437 111.703 91.557 113.76 91.3111 114.25C91.1144 114.74 91.0161 115.229 91.0161 115.768C91.0161 116.307 90.8686 116.796 90.6227 117.286C90.1802 117.972 89.2459 118.363 89 119.147C88.705 120.028 89.4426 120.959 89.2459 121.889C89.1476 122.379 88.8034 122.771 88.6558 123.261C88.5575 123.799 88.6558 124.338 88.9017 124.779C89.5409 125.905 90.9177 126.444 92.1962 126.493C93.4746 126.542 94.7531 126.15 96.0315 125.758"
        fill="#263238"
      />
      <path
        d="M103.063 114.201C102.916 114.642 103.014 115.131 103.358 115.474C103.702 115.817 104.046 116.062 104.44 116.356C104.833 116.6 105.079 117.041 105.177 117.482C105.227 117.923 104.981 118.314 105.079 118.755C105.227 119.833 106.948 120.175 107.046 121.253C107.095 121.987 106.358 122.624 106.554 123.358C106.653 123.603 106.8 123.848 106.997 124.093C107.488 124.926 107.144 126.052 106.456 126.64C105.718 127.276 104.735 127.472 103.801 127.521C103.014 127.57 102.08 127.423 101.637 126.786C101.195 126.199 101.293 125.366 101.391 124.681C101.785 121.302 101.735 117.874 101.637 114.446"
        fill="#263238"
      />
      <path
        d="M94.8515 129.872C94.8515 128.109 94.8515 126.199 94.8515 126.199C94.8515 126.199 92.5405 125.807 92.0979 122.82C91.6554 119.979 92.393 113.809 92.393 113.809C95.7366 112.487 99.4736 113.026 101.785 115.67L101.44 130.116C101.391 131.782 99.9162 133.104 98.0968 133.055C96.2775 132.957 94.8024 131.488 94.8515 129.872Z"
        fill="#FFC7C6"
      />
      <path
        d="M94.8022 126.199C96.2282 126.248 97.605 125.905 98.8343 125.17C98.8343 125.17 97.8509 127.227 94.8514 126.884L94.8022 126.199Z"
        fill="#F9BDBD"
      />
      <path
        d="M100.457 113.515C99.3752 115.082 96.4249 116.698 94.3597 117.041C94.6056 116.747 95.884 115.915 96.1299 115.621C95.1956 116.307 93.8188 117.286 92.5895 117.335C92.442 117.335 92.2945 117.335 92.147 117.286C91.9995 117.188 91.9011 116.992 91.9503 116.796C91.9011 115.915 91.9011 114.984 92.0486 114.103C92.147 113.368 92.5404 112.634 93.1304 112.193C93.9172 111.556 95.0973 111.409 96.179 111.409C97.0641 111.361 97.9984 111.458 98.8343 111.752C99.6702 112.095 100.359 112.732 100.506 113.466"
        fill="#263238"
      />
      <path
        d="M103.653 120.518C103.801 119.882 103.899 119.196 103.948 118.51C104.046 116.943 103.604 111.263 98.2443 111.752L98.0476 113.368C98.0476 113.368 97.2117 118.902 100.703 118.951C100.949 120.175 101.539 121.351 102.424 122.232C102.375 122.232 103.26 122.134 103.653 120.518Z"
        fill="#263238"
      />
      <path
        d="M100.654 118.951C100.998 118.902 101.293 118.902 101.637 119C101.932 119.098 102.178 119.343 102.325 119.637C102.424 119.93 102.424 120.273 102.325 120.567C102.178 120.861 102.03 121.155 101.785 121.351C101.637 121.498 101.44 121.644 101.195 121.742C100.949 121.84 100.703 121.791 100.506 121.644"
        fill="#FFC7C6"
      />
      <path
        d="M101.736 121.596C101.44 121.791 101.145 122.036 100.9 122.33C100.654 122.624 100.506 122.967 100.555 123.358C100.605 123.554 100.605 123.75 100.605 123.946C100.506 124.191 100.359 124.436 100.162 124.681C99.8178 125.317 99.9162 126.15 100.408 126.689C100.949 127.227 101.637 127.472 102.375 127.472C102.817 127.521 103.211 127.423 103.604 127.178C104.096 126.835 104.292 126.199 104.292 125.562"
        fill="#263238"
      />
      <path
        opacity="0.3"
        d="M88.1641 146.081C88.1641 146.081 95.3922 152.888 99.6701 151.664C103.948 150.44 105.226 147.207 105.226 147.207L105.325 146.032L104.882 145.738L88.3607 144.416L88.1641 146.081Z"
        fill="black"
      />
      <path
        d="M83.8862 139.715L85.263 136.189L90.2293 139.323L85.8039 146.13C85.8039 146.081 84.0337 143.73 83.8862 139.715Z"
        fill="#F9BDBD"
      />
      <path
        d="M89.4918 129.921C91.8028 128.696 94.8023 128.354 94.8023 128.354C95.6873 130.068 98.4409 130.312 100.064 129.137L101.49 128.207C101.686 128.207 101.883 128.207 102.08 128.256C105.374 128.549 108.079 130.998 108.718 134.23C109.013 135.454 109.111 136.728 109.062 138.001C108.865 141.184 105.62 141.429 105.62 141.429C105.62 141.429 105.472 144.563 105.227 147.061C105.128 148.872 103.997 150.44 102.375 151.125C101.244 151.713 99.9652 152.056 98.736 152.251C94.7039 152.888 90.6227 151.468 87.8199 148.481C88.3117 145.493 88.8034 141.576 88.7542 141.429V141.38L85.5089 138.491L83.6404 136.923C83.6404 136.875 87.6724 130.9 89.4918 129.921Z"
        fill="white"
      />
      <path
        d="M80.0509 127.521C80.2475 127.717 86.5906 136.238 86.5906 136.238L85.7056 136.728L79.1658 128.305L80.0509 127.521Z"
        fill="#263238"
      />
      <path
        d="M104.932 133.593C104.932 133.593 98.441 144.367 98.0476 144.563C97.2609 144.955 88.951 138.197 88.115 137.462C88.0167 137.315 87.3283 135.307 86.8366 134.622C86.8366 134.622 86.099 131.341 85.3615 131.586C84.5747 131.83 85.804 133.251 85.2631 134.524C84.7714 135.797 82.4603 132.81 82.362 132.516L81.772 131.733C81.772 131.733 81.1327 133.496 81.1819 134.23C81.3294 136.581 86.0498 139.568 86.0498 139.568C86.0498 139.568 92.6388 150.733 99.5228 151.125C101.49 151.223 105.276 145.542 107.636 141.331C109.062 138.784 108.374 136.238 106.063 134.524L104.932 133.593Z"
        fill="#FFC7C6"
      />
      <path
        d="M104.636 130.655C104.391 130.802 98.8342 141.086 98.8342 141.086L106.013 146.669C107.095 143.975 108.078 141.184 108.865 138.393C109.111 137.364 109.013 134.671 108.619 133.642C108.078 132.173 107.292 132.516 106.062 131.684L104.636 130.655Z"
        fill="white"
      />
      <path
        d="M26.4543 11.3123H72.9704C75.8715 11.3123 78.1825 13.3691 78.1825 15.9156V36.5325C78.1825 39.079 75.8223 41.1358 72.9704 41.1358H67.6599V47.8449C67.6599 48.2366 67.2665 48.5794 66.824 48.5794C66.6273 48.5794 66.4306 48.4815 66.2339 48.3835L58.0715 41.1848H26.4543C23.5532 41.1848 21.2422 39.128 21.2422 36.5815V15.8667C21.2422 13.3691 23.5532 11.3123 26.4543 11.3123Z"
        fill="white"
      />
      <path
        d="M26.4543 11.3123H72.9704C75.8715 11.3123 78.1825 13.3691 78.1825 15.9156V36.5325C78.1825 39.079 75.8223 41.1358 72.9704 41.1358H67.6599V47.8449C67.6599 48.2366 67.2665 48.5794 66.824 48.5794C66.6273 48.5794 66.4306 48.4815 66.2339 48.3835L58.0715 41.1848H26.4543C23.5532 41.1848 21.2422 39.128 21.2422 36.5815V15.8667C21.2422 13.3691 23.5532 11.3123 26.4543 11.3123Z"
        fill="#D9FCF0"
      />
      <path
        d="M27.782 26.5914H72.0853C72.3312 26.5914 72.577 26.1506 72.577 25.6119C72.577 25.0732 72.3803 24.6325 72.0853 24.6325H27.782C27.5361 24.6325 27.2903 25.0732 27.2903 25.6119C27.2903 26.1506 27.5361 26.5914 27.782 26.5914Z"
        fill="white"
      />
      <path
        d="M27.782 32.4189H71.3969C71.8394 32.4189 72.2328 31.9782 72.2328 31.4395C72.2328 30.9008 71.8394 30.4601 71.3969 30.4601H27.782C27.3394 30.4601 26.946 30.9008 26.946 31.4395C26.946 31.9782 27.3394 32.4189 27.782 32.4189Z"
        fill="white"
      />
      <path
        d="M27.782 20.8617H71.3969C71.8394 20.8617 72.2328 20.421 72.2328 19.8823C72.2328 19.3436 71.8394 18.9029 71.3969 18.9029H27.782C27.3394 18.9029 26.946 19.3436 26.946 19.8823C26.946 20.421 27.3394 20.8617 27.782 20.8617Z"
        fill="white"
      />
      <path
        d="M253.921 103.623H216.255C213.895 103.623 212.027 105.288 212.027 107.345V124.044C212.027 126.101 213.944 127.766 216.255 127.766H220.582V133.202C220.582 133.544 220.877 133.789 221.271 133.789C221.418 133.789 221.615 133.74 221.713 133.642L228.351 127.815H253.97C256.33 127.815 258.198 126.15 258.198 124.093V107.296C258.149 105.239 256.232 103.623 253.921 103.623Z"
        fill="white"
      />
      <path
        d="M253.921 103.623H216.255C213.895 103.623 212.027 105.288 212.027 107.345V124.044C212.027 126.101 213.944 127.766 216.255 127.766H220.582V133.202C220.582 133.544 220.877 133.789 221.271 133.789C221.418 133.789 221.615 133.74 221.713 133.642L228.351 127.815H253.97C256.33 127.815 258.198 126.15 258.198 124.093V107.296C258.149 105.239 256.232 103.623 253.921 103.623Z"
        fill="#D9FCF0"
      />
      <path
        d="M252.839 115.964H216.944C216.747 115.964 216.551 115.621 216.551 115.18C216.551 114.74 216.747 114.397 216.944 114.397H252.839C253.036 114.397 253.232 114.74 253.232 115.18C253.232 115.621 253.036 115.964 252.839 115.964Z"
        fill="white"
      />
      <path
        d="M252.839 120.665H217.534C217.14 120.665 216.845 120.322 216.845 119.882C216.845 119.441 217.14 119.098 217.534 119.098H252.839C253.232 119.098 253.527 119.441 253.527 119.882C253.527 120.371 253.183 120.665 252.839 120.665Z"
        fill="white"
      />
      <path
        d="M252.839 111.361H217.534C217.14 111.361 216.845 111.018 216.845 110.577C216.845 110.136 217.14 109.793 217.534 109.793H252.839C253.232 109.793 253.527 110.136 253.527 110.577C253.527 111.018 253.183 111.361 252.839 111.361Z"
        fill="white"
      />
      <path
        d="M201.357 83.3C208.281 83.3 213.895 77.9503 213.895 71.351C213.895 64.7518 208.281 59.4021 201.357 59.4021C194.432 59.4021 188.818 64.7518 188.818 71.351C188.818 77.9503 194.432 83.3 201.357 83.3Z"
        fill="#9378F8"
      />
      <path
        d="M200.127 64.4461C198.308 64.4951 196.833 63.2218 196.784 61.5078L196.439 47.0613C198.751 44.4169 202.537 43.8782 205.831 45.2004C205.831 45.2004 206.52 51.3218 206.126 54.2111C205.684 57.1984 203.373 57.5901 203.373 57.5901C203.373 57.5901 203.373 59.451 203.373 61.263C203.422 62.977 201.947 64.3971 200.127 64.4461Z"
        fill="#FFC7C6"
      />
      <path
        d="M203.373 58.3737C200.373 58.6675 199.39 56.6597 199.39 56.6597C200.619 57.3453 201.996 57.737 203.422 57.6881L203.373 58.3737Z"
        fill="#F9BDBD"
      />
      <path
        d="M197.718 44.8576C197.865 44.0251 198.603 43.3395 199.537 42.9967C200.471 42.6539 201.455 42.556 202.438 42.6049C203.618 42.6539 204.848 42.8008 205.733 43.4864C206.372 44.0251 206.814 44.7597 206.913 45.5922C207.06 46.5716 207.06 47.551 207.011 48.5304C207.011 48.7263 206.913 48.9222 206.765 49.0202C206.618 49.0691 206.47 49.1181 206.274 49.0691C204.897 49.0202 203.471 47.9918 202.438 47.2082C202.684 47.551 204.11 48.3835 204.356 48.7263C202.094 48.3346 198.947 46.5716 197.767 44.9066"
        fill="#263238"
      />
      <path
        d="M194.62 51.9584C194.964 53.6235 196.685 54.407 196.685 54.407C197.57 53.5255 197.374 51.6156 197.57 50.3914C201.012 50.3424 200.226 44.8086 200.226 44.8086L200.029 43.1926C194.669 42.7519 194.227 48.4325 194.325 49.9506C194.374 50.6362 194.473 51.3218 194.62 51.9584Z"
        fill="#263238"
      />
      <path
        d="M197.718 52.644C197.521 52.7909 197.275 52.8399 197.029 52.742C196.784 52.644 196.587 52.5461 196.439 52.3502C196.193 52.1053 195.997 51.8605 195.898 51.5667C195.8 51.2728 195.8 50.93 195.898 50.6362C196.046 50.3424 196.292 50.0975 196.587 49.9996C196.882 49.9016 197.226 49.8527 197.57 49.9506"
        fill="#FFC7C6"
      />
      <path
        opacity="0.3"
        d="M209.863 75.4646L193.342 76.7868L192.899 77.0807L192.998 78.256C192.998 78.256 194.276 81.4391 198.554 82.7123C202.832 83.9366 210.06 77.1296 210.06 77.1296L209.863 75.4646Z"
        fill="black"
      />
      <path
        d="M212.42 77.0807L207.995 70.2737L212.961 67.1395L214.338 70.6654C214.239 74.73 212.42 77.0807 212.42 77.0807Z"
        fill="#F9BDBD"
      />
      <path
        d="M208.732 60.8712C210.552 61.8506 214.584 67.8251 214.584 67.8251L212.715 69.3922L209.47 72.2815V72.3305C209.421 72.5263 209.912 76.3951 210.404 79.3823C207.601 82.3696 203.52 83.7897 199.488 83.1531C198.21 82.9572 196.98 82.6144 195.849 82.0268C194.177 81.3412 193.096 79.7251 192.997 77.9621C192.752 75.4156 192.604 72.3305 192.604 72.3305C192.604 72.3305 189.31 72.0366 189.162 68.9025C189.113 67.6292 189.26 66.356 189.506 65.1317C190.145 61.8996 192.85 59.5 196.144 59.1572C196.341 59.1572 196.538 59.1082 196.734 59.1082L198.16 60.0387C199.832 61.214 202.586 61.0181 203.422 59.2551C203.422 59.3041 206.421 59.6469 208.732 60.8712Z"
        fill="white"
      />
      <path
        d="M219.058 59.3041L212.518 67.6782L211.633 67.1885C211.633 67.1885 218.026 58.6675 218.173 58.4716L219.058 59.3041Z"
        fill="#263238"
      />
      <path
        d="M192.161 65.4255C189.801 67.1395 189.162 69.735 190.588 72.2325C192.948 76.444 196.734 82.1247 198.701 82.0268C205.585 81.635 212.174 70.4696 212.174 70.4696C212.174 70.4696 216.895 67.4823 217.042 65.1317C217.091 64.3971 216.452 62.6342 216.452 62.6342L215.862 63.4177C215.764 63.7115 213.453 66.6988 212.961 65.4255C212.469 64.1523 213.649 62.7321 212.863 62.4873C212.076 62.2424 211.387 65.5235 211.387 65.5235C210.896 66.2091 210.207 68.2169 210.109 68.3638C209.224 69.0984 200.963 75.8564 200.176 75.4646C199.783 75.2687 193.292 64.4951 193.292 64.4951L192.161 65.4255Z"
        fill="#FFC7C6"
      />
      <path
        d="M192.161 62.6831C190.932 63.5646 190.145 63.1728 189.605 64.642C189.211 65.6704 189.113 68.3638 189.359 69.3922C190.145 72.1835 191.129 74.9749 192.211 77.6683L199.39 72.0856C199.39 72.0856 193.833 61.8017 193.587 61.6547L192.161 62.6831Z"
        fill="white"
      />
      <path
        d="M194.964 51.077C195.013 50.4403 194.472 49.6078 193.932 49.1181C193.341 48.5794 193.096 47.7469 193.391 46.9634C193.587 46.1798 194.079 46.2778 194.276 45.7881C194.374 45.4453 194.423 45.1025 194.374 44.7107C194.522 43.4864 195.653 42.6049 196.882 42.8008C196.931 42.8008 196.98 42.8008 197.029 42.8498C197.472 41.1848 199.193 40.2543 200.865 40.6951C201.701 40.9399 202.389 41.4786 202.782 42.2621C203.962 41.6745 205.388 42.1152 205.978 43.2905C206.126 43.5844 206.224 43.8782 206.224 44.172C206.962 43.7313 207.896 43.8292 208.486 44.5148C209.027 45.2004 209.076 46.1309 208.634 46.8654C208.191 47.6 207.454 48.0897 206.618 48.1877C205.782 48.2856 204.946 48.1387 204.208 47.7469C203.471 47.3551 202.782 46.8654 202.192 46.2288"
        fill="#263238"
      />
    </svg>
  );
};
