import { Button, Text } from 'cliengo-ds';
import { useEffect } from 'react';
import confetti from 'canvas-confetti';

import { SuccessPicture } from './successPicture';

const confettis = [
  {
    particleCount: 1000,
    angle: 80,
    spread: 1000,
    origin: {
      x: 0,
      y: 0.3,
    },
    zIndex: 0,
  },

  {
    particleCount: 1000,
    angle: 80,
    spread: 1000,
    origin: {
      x: 1,
      y: 0.3,
    },
    zIndex: 0,
  },
  {
    particleCount: 1000,
    angle: 80,
    spread: 1000,
    origin: {
      x: 0.5,
      y: 1,
    },
    zIndex: 0,
  },
];

export const SuccessConection = () => {
  useEffect(() => {
    confettis.forEach((c) => {
      void confetti(c);
    });
  }, []);

  return (
    <div className="min-h-screen flex-col gap-8 flex-center">
      <Text size="h2" className="text-primary">
        Dispositivo vinculado
      </Text>
      <SuccessPicture className="max-w-sm" />
      <Text size="h6" className="text-primary !font-semibold">
        Tu dispositivo ha sido vinculado con éxito
      </Text>
      <a href="https://web.whatsapp.com/" target="_blank" rel="noreferrer">
        <Button className="text-[16px] px-8">Ir a Whatsapp Web</Button>
      </a>
    </div>
  );
};
