import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import { Show, Spinner } from 'cliengo-ds';
import { useEffect } from 'react';

import { AlertIcon } from './alertIcon';
import { FirstStep } from './firstStepIcon';
import { SecondStep } from './secondStep';
import { ThirdStep } from './thirdStep';

import { getSocket } from '@/socket/conn';
import { qr } from '@/signals';

export const MainView = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const socket = getSocket();

    socket.on('connect', () => {
      // eslint-disable-next-line no-console
      console.log('connected');
    });

    socket.on('qr', (qrString: string) => {
      qr.value = qrString;
    });

    socket.on('authenticated', () => {
      navigate('/success');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="min-h-screen flex-col flex-center">
      <div className="container flex-col flex gap-8">
        <h1 className="!font-bold text-center mt-10 text-primary !text-2xl">
          Escanea el QR y vincula tu dispositivo
        </h1>
        <div className="flex justify-between gap-5 mx-9">
          <div className="flex justify-around flex-col">
            <div className="flex gap-4 flex-col">
              <div className="flex gap-2">
                <AlertIcon />
                <p>
                  Mantén tu teléfono <strong>siempre conectado</strong> y con{' '}
                  <br />
                  buena señal de WiFi
                </p>
              </div>
              <div className="flex gap-2">
                <AlertIcon />
                <p>última versión de whatsapp</p>
              </div>
            </div>

            <div className="flex-col gap-5 flex">
              <h3 className="flex text-gray-700 text-base text-center !font-bold">
                Pasos para vincular
              </h3>
              <div className="flex gap-2">
                <FirstStep />
                <p>Abre WhatsApp en tu teléfono</p>
              </div>
              <div className="flex gap-2">
                <SecondStep />
                <p>
                  Ir a <strong>Menú o Configuración </strong>
                  {'>'} Dispositivos <br /> vinculados {'>'} Vincular un
                  dispositivo
                </p>
              </div>
              <div className="flex gap-2">
                <ThirdStep />
                <p>
                  Apunta tu teléfono hacia esta <br />
                  pantalla y <strong>escanea el código QR</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="h-96 w-80 bg-gray-200 flex-center flex-col items-center">
            <p className="text-gray-800 text-xs mb-3">
              Escanea este código QR para solicitarlo
            </p>
            <div className="bg-white h-56 w-56 flex-center">
              <Show
                when={qr.value === ''}
                render={<Spinner color="primary" />}
                else={
                  <QRCode
                    size={256}
                    style={{ height: 'auto', maxWidth: '90%', width: '90%' }}
                    value={qr.value}
                    viewBox={`0 0 256 256`}
                  />
                }
              />
            </div>
            <p className="text-gray-800 text-xs mt-3">
              ¿Necesitás ayuda para conectarte?
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
