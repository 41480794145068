export const AlertIcon = () => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19_4145)">
        <path
          d="M11.4583 16.125H13.5416V18.2083H11.4583V16.125ZM11.4583 7.79166H13.5416V14.0417H11.4583V7.79166ZM12.4896 2.58333C6.73956 2.58333 2.08331 7.25 2.08331 13C2.08331 18.75 6.73956 23.4167 12.4896 23.4167C18.25 23.4167 22.9166 18.75 22.9166 13C22.9166 7.25 18.25 2.58333 12.4896 2.58333ZM12.5 21.3333C7.89581 21.3333 4.16665 17.6042 4.16665 13C4.16665 8.39583 7.89581 4.66666 12.5 4.66666C17.1041 4.66666 20.8333 8.39583 20.8333 13C20.8333 17.6042 17.1041 21.3333 12.5 21.3333Z"
          fill="#FFA621"
        />
      </g>
      <defs>
        <clipPath id="clip0_19_4145">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
