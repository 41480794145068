import { io, Socket } from 'socket.io-client';

let socket: Socket;

export const getSocket = () => {
  if (!socket) {
    socket = io('https://cliengo-cheap-whatsapp.herokuapp.com', {
      transports: ['websocket'],
    });
  }

  return socket;
};
