export const ThirdStep = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#00AD87" fillOpacity="0.5" />
      <path
        d="M10.046 15.126C9.52333 15.126 8.99133 15.056 8.45 14.916C7.90867 14.7667 7.43733 14.5473 7.036 14.258C6.88667 14.1647 6.784 14.0527 6.728 13.922C6.68133 13.782 6.672 13.656 6.7 13.544C6.728 13.4227 6.784 13.32 6.868 13.236C6.96133 13.152 7.07333 13.1053 7.204 13.096C7.33467 13.0773 7.47933 13.1147 7.638 13.208C8.03933 13.4507 8.43133 13.628 8.814 13.74C9.19667 13.8427 9.59333 13.894 10.004 13.894C10.452 13.894 10.83 13.8287 11.138 13.698C11.446 13.5673 11.6747 13.376 11.824 13.124C11.9827 12.872 12.062 12.5687 12.062 12.214C12.062 11.682 11.8847 11.2853 11.53 11.024C11.1847 10.7533 10.6807 10.618 10.018 10.618H9.066C8.85133 10.618 8.688 10.5667 8.576 10.464C8.464 10.352 8.408 10.198 8.408 10.002C8.408 9.81533 8.464 9.67067 8.576 9.568C8.688 9.456 8.85133 9.4 9.066 9.4H9.878C10.2793 9.4 10.62 9.33467 10.9 9.204C11.18 9.064 11.3947 8.87267 11.544 8.63C11.7027 8.378 11.782 8.07467 11.782 7.72C11.782 7.244 11.6233 6.88 11.306 6.628C10.998 6.36667 10.5453 6.236 9.948 6.236C9.56533 6.236 9.20133 6.28733 8.856 6.39C8.52 6.49267 8.17 6.67467 7.806 6.936C7.666 7.02933 7.53067 7.07133 7.4 7.062C7.27867 7.04333 7.17133 6.99667 7.078 6.922C6.994 6.838 6.93333 6.73533 6.896 6.614C6.868 6.49267 6.87733 6.36667 6.924 6.236C6.97067 6.10533 7.064 5.99333 7.204 5.9C7.596 5.61067 8.03933 5.39133 8.534 5.242C9.02867 5.08333 9.53733 5.004 10.06 5.004C10.7133 5.004 11.2733 5.10667 11.74 5.312C12.216 5.51733 12.5753 5.81133 12.818 6.194C13.07 6.57667 13.196 7.034 13.196 7.566C13.196 7.96733 13.1213 8.33133 12.972 8.658C12.8227 8.98467 12.608 9.26467 12.328 9.498C12.0573 9.722 11.726 9.88067 11.334 9.974V9.82C12.0153 9.932 12.5427 10.212 12.916 10.66C13.2893 11.0987 13.476 11.6493 13.476 12.312C13.476 12.8907 13.336 13.3947 13.056 13.824C12.7853 14.244 12.3933 14.566 11.88 14.79C11.3667 15.014 10.7553 15.126 10.046 15.126Z"
        fill="#00522F"
      />
    </svg>
  );
};
